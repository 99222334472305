import { Component, OnInit } from '@angular/core';
import { Location, ILocationResponse } from './Location';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../api.service';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-verbindungssuche',
  templateUrl: './verbindungssuche.component.html',
  styleUrls: ['./verbindungssuche.component.sass']
})
export class VerbindungssucheComponent implements OnInit {
  filteredLocation: Location[] = [];
  locationForm: FormGroup;
  isLoading = false;

  constructor(private fb: FormBuilder, private apiService: ApiService) { }

  ngOnInit() {
    this.locationForm = this.fb.group({
      locationInput: null
    });
    this.locationForm
      .get('locationInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.apiService.getLocationName({ name: value }, 1)
          .pipe(
            finalize(() => this.isLoading = false),
          )
        )
      )
      .subscribe(location => { this.filteredLocation = location.results; console.log(); });
  }

  displayFn(location: Location) {
    if (location) { return location.name; }
  }
}
