import { Identifiers } from '@angular/compiler';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IChangeCheckboxEvent } from '../../interface/IChangeCheckboxEvent';
import { IOptionGroupDetails } from '../../interface/IOptionGroupDetails';


@Component({
  selector: 'app-button-menue',
  templateUrl: './button-menue.component.html',
  styleUrls: ['./button-menue.component.sass']
})
export class ButtonMenueComponent implements OnInit {
  @Input() menueOption: string;
  @Output() eventChangeCheckbox: EventEmitter<IChangeCheckboxEvent> = new EventEmitter<IChangeCheckboxEvent>();
  @Output() eventCloseOptionGroup: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  // tslint:disable-next-line:max-line-length
  // checkValue(egroup: string, eid: string, ecolor: string, eweight: string, edashArray: string, eicon: string, eindex: number, changeEvent: Event) {
  //   const event: IChangeCheckboxEvent = { group: egroup, id: eid, color: ecolor, weight: eweight, dashArray: edashArray,
  //     icon: eicon, index: eindex, checked: changeEvent.returnValue };
  //   this.eventChangeCheckbox.emit(event);
  // }
  checkValue(eDetails: IOptionGroupDetails, changeEvent: Event) {
      const event: IChangeCheckboxEvent = { group: eDetails.group, id: eDetails.id, color: eDetails.color, weight: eDetails.weight,
        dashArray: eDetails.dashArray, icon: eDetails.icon, index: eDetails.index, checked: changeEvent.returnValue };
      this.eventChangeCheckbox.emit(event);
  }

  click_x() {
    this.eventCloseOptionGroup.emit('');
  }

  click_onoff(cb: string, optionDetails: IOptionGroupDetails[]) {
    const state = (document.getElementById(cb) as HTMLInputElement).checked;
    console.log(document.getElementById(cb) as HTMLInputElement);
    optionDetails.forEach(od => {
      const elem = document.getElementById(od.id) as HTMLInputElement;
      if (elem.checked === !state) {
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        elem.dispatchEvent(event);
      }
    });
  }
}
