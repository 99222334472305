import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as xmljs from 'xml-js';
import { Location, ILocationResponse } from './template/verbindungssuche/Location';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_KEY = '295a9969-54d5-4b36-8404-a3eb2b964de5';

  constructor(private http: HttpClient) { }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Accept', 'text/xml');
    return headers;
  }

  public getLocationName(filter: { name: string }, page = 1): Observable<ILocationResponse> {
    //  let response: Observable<ILocationResponse>;
    return this.http.get('/hapi/location.name?input=' + filter.name + '&maxNo=100&accessId=' + this.API_KEY,
      { headers: this.getHeaders(), responseType: 'text' })
      .pipe(
        map(data => {
          return this.parseXMLLocationName(data);
        })
      );

/*     return this.http.get<ILocationResponse>('/hapi/location.name?input=' + filter.name + '&maxNo=100&accessId=' + this.API_KEY,
      { headers: this.getHeaders() })
      .pipe(
        tap((response: ILocationResponse) => {
          console.log('Test');
          response.results = response.results
            .map(location => new Location('111', 'Frankfurt'));
          return response;
        })
      );
 */  }

  private parseXMLLocationName(data): ILocationResponse {
    // console.log(xmljs.xml2js(data, {compact: true, ignoreDeclaration: true, alwaysArray: true}));
    const json = JSON.parse(xmljs.xml2json(data, { compact: true, ignoreDeclaration: true, alwaysArray: true }));
    //console.log(json.LocationList[0].StopLocation[0]._attributes.name);
    const locationList: Location[] = [];
    json.LocationList[0].StopLocation.forEach(element => {
      locationList.push({ id: element._attributes.extId, name: element._attributes.name });
    });
    const response: ILocationResponse = { total: locationList.length, results: locationList };
    return response;
  }
}
