import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.sass']
})
export class CarouselComponent implements OnInit {

  @Output() eventClickedImg: EventEmitter<[string, string, string]> = new EventEmitter<[string, string, string]>();

  slides = [
    // Bilddatei, Unterschrift, ,Aktivität, POI-Kategorie, POI-Id
    ['01_On-Demand-Angebot DadiLiner.jpg', 'On-Demand-Angebot DadiLiner', '', '', ''],
    ['02_Wanderwege.jpg', 'Wanderwege', '', ''],
    ['03_Wamboltsches_Schloss_Groß-Umstadt.jpg', 'Wamboltsches Schloss Groß-Umstadt', 'POI', '10001', 'POI_10129'],
    ['04_Herbstfreuden.jpg', 'Herbstfreuden', '', '', ''],
    ['05_Caravaning & Camping.jpg', 'Caravaning & Camping', '', '', ''],
    ['06_Zuckerstraße_in_Dieburg.jpg', 'Zuckerstraße in Dieburg', 'POI', '10004', 'POI_10134'],
    ['07_Natur erleben.jpg', 'Natur erleben', '', '', '']
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true
  };

    constructor() { }

  ngOnInit(): void {
  }

  clickImg(activity: string, kat: string, id: string): void {
    this.eventClickedImg.emit([activity, kat, id]);
  }

}
