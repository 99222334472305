import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';
import { MatomoModule } from 'ngx-matomo';
import { HttpClientModule } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ApiService } from './api.service';

import { AppComponent } from './app.component';
import { ButtonBarComponent } from './template/button-bar/button-bar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonMenueComponent } from './template/button-menue/button-menue.component';
import {PopupsComponent, ImpressumSheet, DatenschutzSheet, InfoSheet, DadiLinerSheet} from './template/popups/popups.component';
import { VerbindungssucheComponent } from './template/verbindungssuche/verbindungssuche.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule , NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { MomentModule } from 'ngx-moment';
import { MatRadioModule } from '@angular/material/radio';
import {MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, MatTooltipModule} from '@angular/material/tooltip';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import 'hammerjs';
import { CarouselComponent } from './template/carousel/carousel.component';

const routes: Routes = [
  {
      path: '',
      component: AppComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ButtonBarComponent,
    ButtonMenueComponent,
    PopupsComponent,
    ImpressumSheet,
    DatenschutzSheet,
    InfoSheet,
    DadiLinerSheet,
    VerbindungssucheComponent,
    CarouselComponent
  ],
  entryComponents: [
    PopupsComponent,
    ImpressumSheet,
    DatenschutzSheet,
    InfoSheet,
    DadiLinerSheet
  ],
  imports: [
    BrowserModule,
    MatomoModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LeafletModule.forRoot(),
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MomentModule,
    MatRadioModule,
    MatTooltipModule,
    RouterModule.forRoot(routes),
    SlickCarouselModule,
    IvyCarouselModule
  ],
  providers: [
    ApiService,
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig}
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    RouterModule
  ]
})
export class AppModule { }
